import React, {useEffect, useMemo, useState} from "react";
import styles from "./WineScreenLayout.module.css";
import {useAppContext, WineMenuCategory} from "../../containers/AppContext";
import {WineType} from "../../enums/WineType.enum";
import WineListCategory from "../WineList/WineListCategory";
import {Theme} from "../../enums/Theme.enum";
import moment from "moment";

interface Props {

}

const WineScreenLayout: React.FC<Props> = () => {
    const {wineMenu} = useAppContext();

    const [whiteTheme, setWhiteTheme] = useState(false);

    const whiteWines: WineMenuCategory | undefined = useMemo(() => {
        return wineMenu?.find((item) => item.type === WineType.WHITE);
    }, [wineMenu])

    const redWines: WineMenuCategory | undefined = useMemo(() => {
        return wineMenu?.find((item) => item.type === WineType.RED);
    }, [wineMenu])

    const pinkWines: WineMenuCategory | undefined = useMemo(() => {
        return wineMenu?.find((item) => item.type === WineType.PINK);
    }, [wineMenu])

    useEffect(() => {
        const interval = setInterval(() => {
            handleGetTheme();
        }, 60000);
        return () => clearInterval(interval);
    }, [])


    const logotypeUrl: string = useMemo(() => {
        if (whiteTheme) {
            return '/images/logotype-black.svg';
        }
        return '/images/logotype-white.svg';
    }, [whiteTheme])

    const handleGetTheme = () => {
        const currentHour = moment().format('HH');
        if (parseInt(currentHour) >= 15) {
            setWhiteTheme(true);
        }else{
            setWhiteTheme(false);
        }
    }

    return (
        <div className={[styles.wrapper, whiteTheme ? styles.whiteTheme : styles.blackTheme].join(" ")}>
            <div className={styles.header}>
                <div><img src={logotypeUrl} alt='Vinotéka Nad Schody' style={{height: "100px"}}/></div>
                <div className={styles.pageTitle}>Čepovaná vína</div>
            </div>
            <div className={styles.body}>
                <div className={styles.category}>
                    {whiteWines && (
                        <WineListCategory item={whiteWines} titleClassName={styles.title}/>
                    )}
                </div>
                <div className={styles.category}>
                    {redWines && (
                        <WineListCategory item={redWines} titleClassName={styles.title}/>
                    )}
                    {pinkWines && (
                        <WineListCategory item={pinkWines} titleClassName={styles.title}/>
                    )}
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.category}>
                    <table className="table-auto w-full">
                        <tr>
                            <td>PET láhev</td>
                            <td>6 Kč / ks</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className={styles.footer}>
                Všechna vína jsou chlazená a obsahují oxid siřičitý. Detailní rozpis vín je k dispozici u obsluhy.
            </div>
        </div>
    )
}

export default WineScreenLayout;