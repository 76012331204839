import React from "react";
import Icon from "../Base/Icon/Icon";
import styles from "./ContentBlock.module.css";
import {TitleType} from "../../enums/TitleType.enum";

interface Props {
    icon?: {
        file: string;
        title: string;
    }
    title: {
        type: TitleType;
        content: string;
    }
    text?: string;
    children?: React.ReactNode | React.ReactNode[];
}

const ContentBlock: React.FC<Props> = ({icon, title, text, children}) => {
    return (
        <div className={styles.wrapper}>
            {icon && <Icon file={icon.file} title={icon.title}/>}
            {title.type === 'h1' && (
                <h1 className={styles.title}>{title.content}</h1>
            )}
            {title.type === 'h2' && (
                <h2 className={styles.title}>{title.content}</h2>
            )}
            {title.type === 'h3' && (
                <h3 className={styles.title}>{title.content}</h3>
            )}
            {text && (
                <p>{text}</p>
            )}
            {children}
        </div>
    )
}

export default ContentBlock;