import React from "react";
import {useAppContext} from "../../containers/AppContext";
import WineListCategory from "./WineListCategory";
import styles from "./WineList.module.css";

interface Props {

}

const WineList: React.FC<Props> = props => {
    const {wineMenu} = useAppContext();

    return (
        <div className={styles.wrapper}>
            {wineMenu?.length && wineMenu.map((item) => {
                return <WineListCategory item={item}/>
            })}
        </div>
    )
}

export default WineList;