import React from 'react';
import Navbar from "../components/Navbar/Navbar";
import Slideshow from "../components/Base/Slideshow/Slideshow";
import ContentBlock from "../components/Content/ContentBlock";
import WineList from "../components/WineList/WineList";
import {TitleType} from "../enums/TitleType.enum";

interface Props {

}

const content = {
    block1: {
        icon: {
            file: 'grapes-light.svg',
            title: 'grapes icon'
        },
        title: {
            content: 'Vinotéka Nad Schody',
            type: TitleType.H1
        },
        text: 'Vinotéka Nad Schody je ideálním místem pro milovníky vína. Nachází se na ulici Schodová, přímo u Lužáneckého parku. Nabízí široký výběr lahvových i čepovaných vín z různých regionů, ale také piva od malých pivovarů. Kromě toho si zde můžete dopřát různé pochutiny, které se skvěle hodí k nabízeným vínům. Vinotéka Nad Schody také slouží jako výdejna zásilek sítě Zásilkovna, což znamená, že si zde můžete vyzvednout své objednávky a zároveň zakoupit lahodná vína.'
    },
    block2: {
        icon: {
            file: 'wine-glass.svg',
            title: 'wine glass icon'
        },
        title: {
            content: 'Nabídka vín',
            type: TitleType.H2
        },
        text: 'Vinotéka Nad Schody nabízí široký výběr bílých i červených vín, a to jak čepovaných, tak i lahvových. Mezi nabízenými druhy vín naleznete například Ryzlink Rýnský, Sauvignon, Pálavu nebo oblíbené Chardonnay. Každé víno je pečlivě vybíráno a skladováno, aby bylo vždy připraveno na podávání v té nejlepší kvalitě. Pokud hledáte skvělé víno, které dokáže podtrhnout každou příležitost, určitě si u nás přijdete na své.'
    },
    block3: {
        icon: {
            file: 'clock-light.svg',
            title: 'wine glass icon'
        },
        title: {
            content: 'Otevírací doba',
            type: TitleType.H2
        },
    },
    block4: {
        icon: {
            file: 'phone-light.svg',
            title: 'phone icon'
        },
        title: {
            content: 'Kontakt',
            type: TitleType.H2
        },
    }
}

const Homepage: React.FC<Props> = props => {
    return (
        <>
            <Slideshow>
                <Navbar/>
            </Slideshow>
            <div className='container mx-auto'>
                <ContentBlock icon={content.block1.icon} title={content.block1.title} text={content.block1.text}/>
                <div className='flex justify-center'>
                    <img src='/images/wineGlasses.webp' alt='wine glasses'/>
                </div>
                <ContentBlock icon={content.block2.icon} title={content.block2.title} text={content.block2.text}>
                    <div>Aktuální nabídka čepovaných vín:</div>
                </ContentBlock>
                <div className='flex justify-center' id='nabidka'>
                    <WineList/>
                </div>
                <div className='flex justify-center my-10'>
                    <img src='/images/bottles.webp' alt='wine bottles'/>
                </div>
                <div id='oteviraciDoba'>
                    <ContentBlock icon={content.block3.icon} title={content.block3.title}>
                        <div className='flex flex-col gap-2 items-center'>
                            <div>Pondělí 12:00 - 18:00</div>
                            <div>Úterý 12:00 - 18:00</div>
                            <div>Středa 12:00 - 18:00</div>
                            <div>Čtvrtek 12:00 - 18:00</div>
                            <div>Pátek 12:00 - 18:00</div>
                            <div>Sobota zavřeno</div>
                            <div>Neděle zavřeno</div>
                        </div>
                    </ContentBlock>
                </div>
                <div id='kontakt'>
                    <ContentBlock icon={content.block4.icon} title={content.block4.title}>
                        <div className='flex flex-col gap-2 items-center'>
                            <div><b>Vinotéka Nad Schody</b></div>
                            <div>Schodová 1288/9, Brno 602 00</div>
                            <div>tel.: <a href='tel:+420704973623'>+420 704 973 623</a></div>
                            <div>email.: <a href='mailto:info@vinotekanadschody.cz'>info@vinotekanadschody.cz</a></div>
                        </div>
                        <div className='flex flex-col gap-2 items-center'>
                            <div><b>Provozovatel</b></div>
                            <div>IndraCorp s.r.o.</div>
                            <div>IČO: 19873212</div>
                            <div>DIČ: CZ19873212</div>
                            <div>Schodová 1288/9</div>
                            <div>Brno 602 00</div>
                            <div>Společnost je zapsána v obchodním rejstříku vedeném
                                Krajským soudem v Brně oddíl C vložka 136337.</div>
                        </div>
                    </ContentBlock>
                </div>
            </div>
        </>
    )
}

export default Homepage;